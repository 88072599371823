import React, { createContext, useContext, useState, useEffect } from "react";


const StateContext = createContext();

const initialState = {
    chat: false,
    cart: false,
    userProfile: false,
    notification: false,
}

export const ContextProvider = ({ children }) => {
    const [token, setToken] = useState(localStorage.getItem("accessTokenOfUserApp"));
    const [activeMenu, setActiveMenu] = useState(true);
    const [isClicked, setIsClicked] = useState(initialState);
    const [screenSize, setScreenSize] = useState(undefined);
    const [ checked, setChecked ] = useState([]);
    const [ userData, setUserData ] = useState([]);
    const [ updateCitys, setUpdateCitys ] = useState(false);
    const [ userRole, setUserRole ] = useState("")
    const [ userID, setUserID ] = useState("")
    const [ socket, setSocket ] = useState("")
    const [ messageFromSocket, setMessageFromSocket ] = useState("")
    const [ renderingIndicatorData, setRenderingIndicatorData ] = useState(false)
    const [ renderingMessage, setRenderingMessages ] = useState(false)
    const [ notifications, setNotifications ] = useState([])
    const [ updateNotifications, setUpdateNotifications ] = useState(true)
    const [ autoData, setAutoData ] = useState(true)

    const handleCheck = (event) => {
      var updatedList = [...checked];
      if (event.target.checked) {
        updatedList = [...checked, event.target.value];
      } else {
        updatedList.splice(checked.indexOf(event.target.value), 1);
      }
      
      setChecked(updatedList);
      localStorage.setItem("selectedItems", updatedList);
      
    };

    
    const handleClick = (clicked) => {
        setIsClicked({ ...initialState, [clicked]: true});
    }

    const handleLogout = () => {
        setToken(null);
        localStorage.removeItem("accessTokenOfUserApp");
    }

    useEffect(() => {
        console.log(autoData)
        if (!autoData) {
            setRenderingIndicatorData(false)
        }
        if (autoData) {
            console.log("AUTO DATA enabled, rendering immediately");
            setRenderingIndicatorData(true);
        }
        const interval = setInterval(() => {
            if (autoData) {
                console.log("AUTO DATA periodic check - rendering set to true");
                setRenderingIndicatorData(true);
            }
        }, 45000);
    
        return () => {
            clearInterval(interval);
            console.log("Cleaning up interval");
        };
    }, [autoData]); 

    useEffect(() => {
        const ws = new WebSocket(`${process.env.REACT_APP_API_WSS}/ws/${userID}`);
        
        ws.onopen = () => {
            // Conexiunea este deschisă, trimite tokenul pentru autentificare
            ws.send(JSON.stringify({ token: token }));
        };
    
        ws.onmessage = (event) => {
            // Mesaj primit de la server
            const message = JSON.parse(event.data);
            console.log('Message from server ', message);
            console.log(message.message.message_type)
            setMessageFromSocket(message)
            setRenderingMessages(true)
            setUpdateNotifications(true)
            // Aici poți adăuga logica pentru gestionarea mesajelor primite
        };
    
        ws.onerror = (error) => {
            // Tratează orice erori
            console.error('WebSocket error: ', error);
        };
    
        ws.onclose = () => {
            // Conexiunea a fost închisă
            console.log('WebSocket disconnected');
        };
    
        // Setează conexiunea WebSocket în starea componentei
        setSocket(ws);
    
        // Curăță conexiunea la demontarea componentei sau la schimbarea dependințelor
        return () => {
            if (ws) {
                ws.close();
            }
        };
    }, [userID, token]); // Dependențele useEffect-ului

    useEffect(() => {
        const fetchUser = async () => {
            const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json"},
            body: JSON.stringify({token}),
            };
    
        const response = await fetch(`${process.env.REACT_APP_API_URL}/usergettoken`, requestOptions);
        const userDataJson = await response.json();
    
        if (!response.ok) {
            setToken(null);
            localStorage.removeItem("accessTokenOfUserApp");
        }
        setUserRole(userDataJson.role)
        setUserID(userDataJson.userid)
        setUserData(userDataJson)
        localStorage.setItem("accessTokenOfUserApp", userDataJson.token);
        localStorage.setItem("role", userDataJson.role);
    
        };
    
        fetchUser();
    
        }, [token]);
        
    



    useEffect(() => {
        const fetchNotifications = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/get_notifications?limit=10`, {
                method: 'GET', // Specifică metoda HTTP DELETE
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`, // Trimite tokenul în header-ul de autorizare
                }
            })

        const datajson = await response.json();
        console.log(datajson)
        if (!response.ok) {
            console.log(datajson);
        }

        setNotifications(datajson)
        setUpdateNotifications(false)

    };

    updateNotifications && fetchNotifications()
    
    }, [updateNotifications, token]);

    return (
        <StateContext.Provider
            value={{
                renderingMessage,
                setRenderingMessages,
                renderingIndicatorData,
                setRenderingIndicatorData,  
                activeMenu,
                setActiveMenu,
                isClicked,
                setIsClicked,
                handleClick,
                screenSize,
                setScreenSize,
                token,
                setToken,
                handleLogout,
                handleCheck,
                checked,
                userData,
                setUserData,
                updateCitys,
                setUpdateCitys,
                setUserRole,
                userRole,
                userID,
                notifications,
                setUpdateNotifications,
                messageFromSocket,
                autoData, 
                setAutoData,
                setUserID
            }}>
            {children}
        </StateContext.Provider>
    )
}

export const useStateContext = () =>  useContext(StateContext);