import React, { useState, useEffect } from "react";
import FullScreenLoader from "../components/smallcomp/FullScreeenLoader"
import { useStateContext } from "./ContextProvider";
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const RequireUser = ({ allowedRoles }) => {
    const [ user, setUser ] = useState("")
    const { token, setToken } = useStateContext();
    
    const location = useLocation();

    useEffect(() => {
        const fetchUser = async () => {
            const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json"},
            body: JSON.stringify({token}),
            };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/usergettoken`, requestOptions);
        const userDataJson = await response.json();

        if (!response.ok) {
            setToken(null);
            localStorage.removeItem("accessTokenOfUserApp");
        }
        setUser(userDataJson)
    };
    fetchUser();
    }, []);

    if (!user) {
        return <FullScreenLoader />;
      }
    
      return token && allowedRoles.includes(user?.role) ? (
        <Outlet />
      ) : token && user ? (
        <Navigate to='/' state={{ from: location }} replace />
      ) : (
        <Navigate to='/login' state={{ from: location }} replace />
      );
}

export default RequireUser;